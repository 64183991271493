import React from "react"
import BlogPostHorizontal from "../blog/blogPostHorizontal"
import { BlogPostType } from "../blog/blogPostSection"

type Props = {
  posts: BlogPostType[]
}

const TheAppSection = ({ posts }: Props) => {
  return (
    <div className="container container-small">
      {posts.map((p, i) => (
        <article key={i} className="row g-5">
          <div className={"col-12 m-0"}>
            <BlogPostHorizontal {...p} />
          </div>
        </article>
      ))}
    </div>
  )
}

export default TheAppSection
