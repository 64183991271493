import classNames from "classnames"
import React from "react"
import { UiColor } from "./types/color"

type LabelProps = {
  label: string
  color: UiColor
  active?: boolean
  lead?: boolean
}

const Label = ({ color, active, lead, label }: LabelProps) => {
  return (
    <span
      className={classNames(
        `text p-1 px-2 category u-bg-${color}`,
        {
          "u-o-1": active,
        },
        { lead: lead }
      )}
    >
      {label}
    </span>
  )
}

export default Label
