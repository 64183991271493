import { Link } from "gatsby"
import React from "react"
import PictureTemplate from "../../components/common/pictureTemplate"
import BlogCategoryLabel from "./blogCategoryLabel"
import { BlogPostType } from "./blogPostSection"

const BlogPostHorizontal = ({
  picture,
  blogCategories,
  heading,
  summary,
  slug = null,
}: BlogPostType) => {
  return (
    <div className="p-3 rounded move-top-on-hover neu-morphism-white">
      <div className="row">
        <div className="col-12 col-md-6">
          {slug ? (
            <Link to={`/blog/post/${slug}`} className="u-o-1">
              <div className="pt-1">
                <h3>{heading}</h3>
                <p>{summary}</p>
              </div>
            </Link>
          ) : (
            <div className="pt-1">
              <h3>{heading}</h3>
              <p>{summary}</p>
            </div>
          )}
          <div className="d-flex my-3">
            {blogCategories.map((bc, i) => (
              <BlogCategoryLabel
                key={i}
                category={bc.category}
                color={bc.color}
                slug={bc.slug}
              />
            ))}
          </div>
        </div>
        <div className="col-12 col-md-6">
          {slug ? (
            <Link to={`/blog/post/${slug}`} className="u-o-1">
              <PictureTemplate {...picture} />
            </Link>
          ) : (
            <PictureTemplate {...picture} />
          )}
        </div>
      </div>
    </div>
  )
}

export default BlogPostHorizontal
