import classNames from "classnames"
import React from "react"

export enum WaveType {
  v1,
  v2,
  v3,
  v4,
  v5,
  v6,
}

type WaveProps = {
  className?: string
  type: WaveType
  pathClassName?: string
  style?: React.CSSProperties
}

const Wave = ({ className, type, pathClassName, style }: WaveProps) => {
  const path = (): string => {
    switch (type) {
      case WaveType.v1:
        return "M0 0L47 11C94 22 188 45 281 51C375 56 469 45 563 34C656 22 750 11 844 11C938 11 1031 22 1078 28L1125 34V135H1078C1031 135 938 135 844 135C750 135 656 135 563 135C469 135 375 135 281 135C188 135 94 135 47 135H0V0Z"
      case WaveType.v2:
        return "M0 0L30.9375 15.0833C62.8125 30.1667 124.688 60.3333 187.5 79.1875C250.312 98.0417 312.188 105.583 375 82.9583C437.812 60.3333 499.688 7.54167 562.5 11.3125C625.312 15.0833 687.188 75.4167 750 98.0417C812.812 120.667 874.688 105.583 937.5 90.5C1000.31 75.4167 1062.19 60.3333 1094.06 52.7917L1125 45.25V181H1094.06C1062.19 181 1000.31 181 937.5 181C874.688 181 812.812 181 750 181C687.188 181 625.312 181 562.5 181C499.688 181 437.812 181 375 181C312.188 181 250.312 181 187.5 181C124.688 181 62.8125 181 30.9375 181H0V0Z"
      case WaveType.v3:
        return "M0 72L50 78C100 84 200 96 300 102C400 108 500 108 600 102C700 96 800 84 900 66C1000 48 1100 24 1150 12L1200 0V144H1150C1100 144 1000 144 900 144C800 144 700 144 600 144C500 144 400 144 300 144C200 144 100 144 50 144H0V72Z"
      case WaveType.v4:
        return "M0 72H46.875C93.75 72 187.5 72 281.25 54C375 36 468.75 0 562.5 0C656.25 0 750 36 843.75 60C937.5 84 1031.25 96 1078.12 102L1125 108V180H1078.12C1031.25 180 937.5 180 843.75 180C750 180 656.25 180 562.5 180C468.75 180 375 180 281.25 180C187.5 180 93.75 180 46.875 180H0V72Z"
      case WaveType.v5:
        return "M0 146.082L46.875 141.762C93.75 137.442 187.5 128.803 281.25 124.483C375 120.164 468.75 120.164 562.5 94.2455C656.25 68.3273 750 16.491 843.75 3.5319C937.5 -9.42718 1031.25 16.491 1078.12 29.4501L1125 42.4092V172H1078.12C1031.25 172 937.5 172 843.75 172C750 172 656.25 172 562.5 172C468.75 172 375 172 281.25 172C187.5 172 93.75 172 46.875 172H0V146.082Z"
      case WaveType.v6:
        return "M0 0L46.875 6C93.75 12 187.5 24 281.25 24C375 24 468.75 12 562.5 18C656.25 24 750 48 843.75 54C937.5 60 1031.25 48 1078.12 42L1125 36V180H1078.12C1031.25 180 937.5 180 843.75 180C750 180 656.25 180 562.5 180C468.75 180 375 180 281.25 180C187.5 180 93.75 180 46.875 180H0V0Z"
    }
  }

  return (
    <svg
      style={style ?? { marginBottom: "-1px" }} //Safari fix
      preserveAspectRatio="none"
      className={className}
      viewBox="0 0 1125 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classNames(
          { "fill-current": !pathClassName },
          { [pathClassName]: pathClassName }
        )}
        fillRule="evenodd"
        clipRule="evenodd"
        d={path()}
      />
    </svg>
  )
}

export default Wave
