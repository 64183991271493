import React from "react"

type TopSectionProps = {
  heading: string
  body: {
    html: string
  }
  className?: string
  dataSal?:
    | "fade"
    | "slide-up"
    | "slide-down"
    | "slide-left"
    | "slide-right"
    | "zoom-in"
    | "zoom-out"
    | "flip-up"
    | "flip-down"
    | "flip-left"
    | "flip-right"
}
const TopSection = ({
  heading,
  body,
  className = "text-center",
  dataSal,
}: TopSectionProps) => {
  return (
    <div
      data-sal={dataSal}
      data-sal-delay="200"
      data-sal-easing="ease"
      className="container container-x-small min-vh-65 d-flex pt-10 pb-5 justify-content-center"
    >
      <article className={className}>
        <h2>{heading}</h2>
        <div
          className="u-lead-md pt-3"
          dangerouslySetInnerHTML={{ __html: body.html }}
        />
      </article>
    </div>
  )
}

export default TopSection
