import classNames from "classnames"
import { Link } from "gatsby"
import React, { useContext } from "react"
import AppContext, { Locale } from "../../common/appContext"
import Label from "../../common/label"
import { UiColor } from "../../common/types/color"

type BlogCategoryLabelProps = {
  category: string
  color: string
  slug?: string
  active?: boolean
  lead?: boolean
}
const BlogCategoryLabel = ({
  category,
  color,
  slug,
  active = true,
  lead = false,
}: BlogCategoryLabelProps) => {
  const { locale } = useContext(AppContext)

  let to: string = locale === Locale.sv ? "/blog" : "/en/blog"
  to = slug ? `${to}/${slug}` : to

  return (
    <div className="p-1">
      <Link className={classNames({ "u-o-1": active })} to={to} replace>
        <Label
          color={color as UiColor}
          lead={lead}
          active={active}
          label={category}
        />
      </Link>
    </div>
  )
}

export default BlogCategoryLabel
